/* eslint-disable no-irregular-whitespace */
export default {
  hero: {
    title: ['Décoration', 'D\'interieur'],
    img: 'https://images.ctfassets.net/r346nz3uql81/348ea5SBy5dZPI0nspxuNn/cb60f7e84e7aa6470471850b77286f31/annesophielagoet_deco_hero.jpg?w=2300&q=50&fm=jpg&fit=fill&f=bottom',
  },
  prestation: {
    description: {
      title: 'Décoration d\'intérieur',
      titleColor: 'gold',
      baseline: 'PARTICULIER ou PROFESSIONNEL, vous avez décidé de décorer votre bien.',
      description: `Vous avez un projet déco ! Il vous faut trouver votre style, harmoniser la palette colorielle ? Peut-être sélectionner
    le mobilier, les accessoires et les textiles ? Vous souhaitez vous tourner sur du mobilier sur-mesure ou encore déléguer la coordination de vos travaux ?
    Je vous propose mes services en architecture d’intérieur pour votre projet de décoration (dans l’ancien ou dans le neuf) avec 
    une prestation sur-mesure adaptée à vos besoins et votre budget.`,
    },
    stepByStep: {
      title: ['« DONNER VIE A VOTRE PROJET', 'OÙ QUE VOUS SOYEZ. »'],
      testimonials: [
        {
          id: 'project',
          title: 'rdv à domicile',
          picto: 'https://images.ctfassets.net/r346nz3uql81/E6ine7YCJ3bOiCa5hNwuC/ce2f9d2d2a2f5cf4ab2765ef6a6d1727/picto_deco_maison.svg',
          text: `
          <p>Nous échangeons à votre domicile sur le projet.</p>
          <p>Devis établis, je m'occupe du métré et du reportage photos indispensable à la réalisation du book.</p>
          </br>
          <p>* Le rdv peut-être payant selon la géolocalisation.</p>`,
        },
        {
          id: 'correspondance',
          title: 'par correspondance',
          picto: 'https://images.ctfassets.net/r346nz3uql81/6e4i3lUGfbabZ5IMIBdgOG/e955bd0fb6dd4feadf5c9d3735a52d5c/picto_deco_enveloppe.svg',
          text: `
          <p>Les échanges se font uniquement par internet, vidéoconférence ou par téléphone. Vous m’envoyez par mail toutes les informations nécessaires à la réalisation du book (photos, mesures…)</p>
          `,
        },
      ],
    },
    prestations: {
      sectionTitle: ['« OPTIMISER &', 'HARMONISER L’ESPACE. »'],
      blockColor: 'prune',
      contentImage: 'https://images.ctfassets.net/r346nz3uql81/63piQJxOVFMVqoCYYsNM3b/493209f58f15b7d39ce743b7eb4091ab/annesophielagoet_deco_photo_palette_prestations.jpg?w=2300&q=50&fit=fill&f=center',
      contentTitle: ['UNE PALETTE', 'DE PRESTATIONS'],
      contentBaseline: 'Des planches outils à mixer selon votre projet :',
      contentList: [
        'planches inspirations & recherche de style',
        'planche shopping déco',
        'planche matériaux',
        'shopping liste',
        'croquis couleur 3D',
        'design meuble à menuiser : dressing, verrière…',
        'agencement de l’espace',
      ],
    },
    projectInImages: {
      sectionTitle: '« PROJETS EN IMAGES. »',
      navigations: [
        'inspiration',
        'agencement',
        'shopping-deco',
        'croquis',
        'materiaux',
        'design-mobilier',
      ],
      toolboards: {
        inspiration: {
          id: 'inspiration',
          name: 'Moodboard',
          description: `
          <p>Aussi appelé planche de style ou inspirations…</p>
          <p>Vous n’avez pas d’idée… ou vous en avez trop ? Le moodboard est l’outil de travail idéal pour créer <strong>le style de votre intérieur</strong> (ambiance, couleurs, matières…). Une étape incontournable avant de se lancer dans un projet déco!</p>
          `,
          picto: 'https://images.ctfassets.net/r346nz3uql81/2GoUJpc6ePBUbpf2H3Bvu2/4adccbb6033fb1d93c7e987e58a75ee5/pastille_deco_moodboard.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/hnhkPfvunmIVk1OeCuyz8/e1ee903b75f118b4aa2cfbada07c77a2/annesophielagoet_Presta_Deco_22_Moodboard.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        'shopping-deco': {
          id: 'shopping-deco',
          name: 'Shopping déco',
          description: `
          <p><strong>Une sélection déco</strong> adaptable selon vos besoins. Du simple canapé à la sélection totale de votre déco (mobilier, textiles, luminaires, accessoires…).</p>
          <p>Elle est accompagnée d’<strong>une shopping liste en pdf</strong> qui facilitera vos futurs achats (refs, prix, photos, liens internet cliquables…).</p>
          `,
          picto: 'https://images.ctfassets.net/r346nz3uql81/2ENeohqckYms3mT9DwBNar/7a8c3b75281dec1cbe56b7da03faa0ed/pastille_deco_shopping_deco.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/5X4la5puKOMhUZRYdEiJ1q/bea9a228a4748aa33b2867698ae3e8d6/annesophielagoet_Presta_Deco_22_Shopping_Deco.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        materiaux: {
          id: 'materiaux',
          name: 'Matériaux',
          description: `
          <p>Peintures, papier peint, sol, crédence, faïence, 
          finition cuisine, façades meuble sdb, essence de bois.
          Je vous guide dans le choix de vos achats matériaux, 
          leur implantation et la réalisation du calepinage.
          </p>
          `,
          picto: 'https://images.ctfassets.net/r346nz3uql81/7KFtD6W4vBD7mM24TYewlL/0b5c13214d8b44993938c44461f227e3/pastille_deco_materiaux.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/PmPqqfbRgwAjunA8b39an/d5d115f559fb6b00acf2a016d44e7790/annesophielagoet_Presta_Deco_22_Materiaux.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        croquis: {
          id: 'croquis',
          name: 'Croquis',
          description: `
          <p>Avec le croquis couleur, <strong>plongez directement dans le rendu final</strong> de votre futur intérieur! Il vous aidera à vous projeter avec votre nouvelle déco (mobilier, accessoires, textiles, matériaux etc…).</p>
          `,
          picto: 'https://images.ctfassets.net/r346nz3uql81/4RclOLNmr7x7GIcWaidHXs/ee682d351f7dca78bb9ba727267e44ff/pastille_deco_croquis.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/PWBik0QVfKVPlm6558ETL/02e70cab73b11a8f379717a4a030cf97/annesophielagoet_Presta_Deco_22_Croquis.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        agencement: {
          id: 'agencement',
          name: 'Agencement',
          description: `
          <p>Vous souhaitez <strong>optimiser votre espace de vie actuel ou futur ?</strong></p>
          <p>Je vous propose ici la meilleure solution d’agencement pour votre pièce en tenant compte de vos meubles actuels (ou futurs).</p>
          `,
          picto: 'https://images.ctfassets.net/r346nz3uql81/3FR5KLL8jO8dtJycSu4ydy/f8f0e090339685c2f1da4660b6e98465/pastille_deco_agencement.svg',
          images: [
            'http://images.ctfassets.net/r346nz3uql81/7DjjIFvyZBcSni5js0jfYP/d81ab4d15928b89d6436a42668def684/annesophielagoet_Presta_Deco_22_Agencement.jpg?w=2300&q=50&fm=jpg',
          ],
        },
        'design-mobilier': {
          id: 'design-mobilier',
          name: 'Design mobilier',
          description: `
          <p>Une verrière? Une bibliothèque? Des rangements ? 
          Le design mobilier apporte des solutions sur-mesure 
          afin d’optimiser et de valoriser votre espace.</p>
          `,
          picto: 'https://images.ctfassets.net/r346nz3uql81/7yr7kVgF6sUcjLljcDykmk/5323027f07af8a46011be9264ce2c8b8/pastille_deco_design_mobilier.svg',
          images: [
            'https://images.ctfassets.net/r346nz3uql81/2uCcYLTuhUFYqvISqz7c34/d80147119300d678749631494a43de97/annesophielagoet_Presta_Deco_22_Shopping_Design_Mobilier.jpg?w=2300&q=50&fm=jpg',
          ],
        },
      },
    },
    gallery: [
      {
        id: 1,
        image: 'https://images.ctfassets.net/r346nz3uql81/1eYqKjY9eHlwcRTgYWKPnh/4d8f481c19176927abbdff4b3e48de9c/annesophielagoet_deco_diaporama_01.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
        tooltip: {
          position: { x: 8, y: 3 },
          text: "Agencement d'espaces",
        },
      },
      {
        id: 2,
        image: 'https:///images.ctfassets.net/r346nz3uql81/1xgXm3uRGwhVE4wdtk6qxo/1b4f1a73558357a6c324d4d28915e389/annesophielagoet_deco_diaporama_02.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
        tooltip: {
          position: { x: 8, y: 3 },
          text: 'Conseils textiles',
        },
      },
      {
        id: 3,
        image: 'https://images.ctfassets.net/r346nz3uql81/2f3B3vlXv3zA312eugVV2Q/38ccc2d4ae225e57bad58c14f2e7fd54/annesophielagoet_deco_diaporama_03.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
      },
      {
        id: 4,
        image: 'https://images.ctfassets.net/r346nz3uql81/2IanOBxX0Mf1GQHBkwQqDL/809bc772eaac0b4d6db2d7d4aa73e03f/annesophielagoet_deco_diaporama_04.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
        tooltip: {
          position: { x: 8, y: 5 },
          text: 'Selection matériaux',
        },
      },
      {
        id: 5,
        image: 'https://images.ctfassets.net/r346nz3uql81/4wAyjaw9wmq1rOCoTdB1uC/970117d31d67ba377465d4435cd43298/annesophielagoet_deco_diaporama_05.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
      },
      {
        id: 6,
        image: 'https://images.ctfassets.net/r346nz3uql81/7831aOHrPV1W3bnNSESzlR/7c96bc57eef77a125bd4cd6e790d8588/annesophielagoet_deco_diaporama_06.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
        tooltip: {
          position: { x: 8, y: 5 },
          text: 'Croquis couleurs 3D',
        },
      },
      {
        id: 7,
        image: 'https://images.ctfassets.net/r346nz3uql81/2R3LOoKCozx9dTqsRzWmF8/90966de7f9c43dbe8f99eae6f33967f1/annesophielagoet_deco_diaporama_07.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
      },
      {
        id: 8,
        image: 'https://images.ctfassets.net/r346nz3uql81/2kNkazITfbpmXsYqOCgtav/e8af67de0d35ce2bfa4f9b3e07807cde/annesophielagoet_deco_diaporama_08.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
        tooltip: {
          position: { x: 8, y: 5 },
          text: 'Design mobilier',
        },
      },
      {
        id: 9,
        image: 'https://images.ctfassets.net/r346nz3uql81/xs5adfYglrNmtkVtIQndr/e3675c6ed7bf3ec7996371a9b496d5ea/annesophielagoet_deco_diaporama_09.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
      },
      {
        id: 10,
        image: 'https://images.ctfassets.net/r346nz3uql81/3oiJul1IgNIBME2HroCw24/9060c23a765e6b0e0101574758c98384/annesophielagoet_deco_diaporama_10.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
      },
      {
        id: 11,
        image: 'https://images.ctfassets.net/r346nz3uql81/2adBPuCsrfbovwifnKUpG2/f0f949d57040123e8a5899848f420e54/annesophielagoet_deco_diaporama_11.jpg?w=1334&h=1334&q=50&fit=fill&f=center',
      },
      {
        id: 12,
        image: 'https://images.ctfassets.net/r346nz3uql81/3DTzByNoo0PLJ4UVtEPivP/e8561e647abc72d133970413a8cc6633/annesophielagoet_deco_diaporama_12.jpg?w=1334&h=1334&q=50&fit=fill',
        tooltip: {
          position: { x: 8, y: 5 },
          text: 'Shopping déco',
        },
      },
    ],
  },
};
