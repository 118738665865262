/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Prestation from '../../templates/Prestation/Prestation';
import data from './data';

const DecorationInterieur = ({ pageContext }) => {
  const { prestation, hero } = data;

  return (
    <Prestation prestation={prestation} hero={hero} pageContext={pageContext} />
  );
};

DecorationInterieur.propTypes = {
  pageContext: PropTypes.any.isRequired,
};

export default DecorationInterieur;
